* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.hii {
  position: fixed;
  left: 20px;
  bottom: 70px;
  color: white;
}
.contactIcon{
  background-color: rgb(7, 143, 37);
  align-items: center;
  text-align: center;
  padding: 12px;
  border-radius: 50px;
  z-index: 100;
  position: fixed;
  cursor: pointer;
}


@tailwind base;
@tailwind components;
@tailwind utilities;