.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 990;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .9);
    margin-bottom: 100px;
    align-items: center;
}

.header .navbar {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: #ffffff;
}


.logo img {
    width: 160px;
    height: auto;
    margin-top: -50px;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
    margin-top: -50px;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}


@media screen and (max-width:940px) {
    .header {
        width: 100%;
        background-color: rgba(0, 0, 0, .9);
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 89px;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
        padding-top: 40px;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    /* .header .navbar img {
        width: 450px;
    } */
    .logo img {
        width: 200px;
        height: 90px;
        margin-top: -20px;
        margin-left: -20px;
    }
}

@media screen and (max-width:500px) {
    .header {
        width: 100%;
        background-color: black;
        margin-bottom: 90px;

    }

    .header .navbar {
        width: 100%;
        margin-bottom: 100px;
        padding-bottom: 100px;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 89px;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
        padding-top: 40px;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    /* .header .navbar img {
        width: 450px;
    } */
    .logo img {
        width: 200px;
        height: 90px;
        margin-top: -20px;
        margin-left: -20px;
    }
}