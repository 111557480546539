.PhotographyPackageComp {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(https://img.freepik.com/free-photo/huge-decorative-circle-made-willow-greenery-pale-orange-roses-with-burning-candles_8353-10644.jpg?w=900&t=st=1700143301~exp=1700143901~hmac=2c076e55dad355a8f030920ab9e8c2a1c5bec9ab5a26b3a309c4567f4f1e72f2);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.beit {
    color: white;
    text-align: center;
    width: 40%;
    margin: auto;
    margin-bottom: -40px;
}

@media screen and (max-width:500px) {
    .PhotographyPackageComp {
        background-position: left;
    }

    .beit {
        width: 80%;
        margin-bottom: 20px;
    }
}