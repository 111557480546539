.GalleryComp {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(https://img.freepik.com/premium-photo/traditional-indian-wedding-ceremony-groom-holding-hand-bride-hand_96696-808.jpg?w=900);
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    background-position: center;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}