.VideoGalleryComp {
    background-color: black;
    padding: 50px 10px;
}

.videogallery {
    width: 50%;
    margin: auto;
    text-align: center;
}

.VideoList {
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 50px;
}