
 a {
    padding: 12px 30px;
    border-radius: 4px;
    outline: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 1px;
    transition: all .5s ease;

}

.btntwo {
    background-color: #000000;
    color: white;

}
.btntwo:hover {
    background: #fff;
    color: black;

}
