.MainAbout {
    background-color: #1a1a1a;
}

.AboutComp {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(https://img.freepik.com/free-photo/beautiful-woman-long-red-dress-walks-around-city-with-her-husband_1157-13373.jpg?w=900&t=st=1700143806~exp=1700144406~hmac=2fa4826d3a602b5ec9bf84a4f4f504e9f2ca98adfd0c5b2ceba254fcde82b1c2);
    background-repeat: no-repeat;
    background-size: cover;
    height: 95vh;
    background-position: center;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.getQuote1 {
    border: 1px solid gold;
    padding: 10px 20px;
    border-radius: 5px;
    color: gold;
    margin-top: 20px;
}

.getQuote1:hover {
    background-color: gold;
    color: black;
}