.final {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.carosalCardComp {
  font-size: 12px;
  background-color: black;
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 40px;
  height: 250px
}

.carosalCardinnerComp {
  height: 200px;
}

.CarosalComp {
  padding: 70px 0;
  text-align: center;
}

.CarosalInnerComp {
  width: 80%;
  margin: auto;
  padding: 30px 0;
}

@media screen and (max-width:500px) {
  .carosalCardComp {
    font-size: 12px;
    background-color: black;
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    height: 1000px
  }

  .carosalCardinnerComp {
    height: 250px;
    text-align: center;
    align-items: center;
  }

  .CarosalInnerComp {
    width: 95%;
    margin: auto;
  }
}