.StatusComp {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: black;
}


.single {
    width: 100%;
    cursor: pointer;
}

.single img {
    max-width: 100%;
    height: 150px;
    resize: both;
}

.single img:hover {
    transform: scale(1.02);
}


.photoList {
    background-color: #1a1a1a;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 50px;
}